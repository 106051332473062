import { makeStyles } from "@mui/styles";
import { ReactElement } from "react";
import { Theme } from "@mui/material";

import twentyfive from "../assests/25.png";
import fifty from "../assests/50.png";
import sevfive from "../assests/75.png";
import hundred from "../assests/100.png";
import oneTtwentyFive from "../assests/125.png";

import { Footer } from "../components/footer";

export const Roadmap = (): ReactElement => {
  const classes = useStyles();
  return (
    <div className={classes.top}>
      <div className={classes.header}>ROAD MAP</div>
      <div className={classes.items}>
        <div className={classes.itemlist}>
          <div className={classes.roadMapMain}>
            <img src={twentyfive} alt="25" className={classes.img} />
            <div className={classes.desc}>MINT OUT GENESIS COLLECTION</div>
          </div>
          <ol type="a">
            <li className={classes.pointer}>
              Goal is to develop a community of NFT and Crypto enthusiasts
            </li>
            <li className={classes.pointer}>
              Revenue sharing will be implemented on secondary sales
            </li>
          </ol>
        </div>
        <p></p>
        <div className={classes.itemlist}>
          <div className={classes.roadMapMain}>
            <img src={fifty} alt="50" className={classes.img} />
            <div className={classes.desc}>DEVELOP THE CBABY STORY</div>
          </div>
          <ol type="a">
            <li className={classes.pointer}>
              A baby is born and learns about Finance, Crypto, and NFT’s
            </li>
            <li className={classes.pointer}>
              The baby turns in to a toddler and gets in to trouble
            </li>
            <li className={classes.pointer}>
              A young adult is developed where skills are honed
            </li>
            <li className={classes.pointer}>
              The baby is now an adult with it’s own family
            </li>
          </ol>
        </div>
        <div className={classes.itemlist}>
          <div className={classes.roadMapMain}>
            <img src={sevfive} alt="75" className={classes.img} />
            <div className={classes.desc}>
              PARTNER WITH LEADING CRYPTO AND NFT PROJECTS
            </div>
          </div>
          <ol type="a">
            <li className={classes.pointer}>
              Projects with similar ideals as CBABY will be sought after for
              partnerships
            </li>
            <li className={classes.pointer}>
              Collaborations, contests, and marketing will be developed
            </li>
          </ol>
        </div>
        <div className={classes.itemlist}>
          <div className={classes.roadMapMain}>
            <img src={hundred} alt="hundred" className={classes.img} />
            <div className={classes.desc}>PLAN FOR FUTURE DROPS</div>
          </div>
          <ol type="a">
            <li className={classes.pointer}>
              The community will be a key part in future drops
            </li>
            <li className={classes.pointer}>
              The future drops will encompass the CBABY story
            </li>
            <li className={classes.pointer}>
              The Genesis CBABY’s will be a mint pass for future drops
            </li>
          </ol>
        </div>
        <div className={classes.itemlist}>
          <div className={classes.roadMapMain}>
            <img src={oneTtwentyFive} alt="hundred" className={classes.img} />
            <div className={classes.desc}>BUILD OUT METAVERSE LAND</div>
          </div>
          <ol type="a">
            <li className={classes.pointer}>
              We will acquire a property in a leading Metaverse project and
              build out an experience for CBABY’s
            </li>
            <li className={classes.pointer}>
              The project will look to be a part of Metaverses that share the
              same ideals as our project
            </li>
          </ol>
        </div>
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  top: {
    marginTop: "-3%",
  },
  header: {
    color: "#fee900",
    fontSize: "30px",
    textAlign: "center",
    fontWeight: "300",
    height: "35px",
    marginBottom: "20px",
    [theme.breakpoints.down(900)]: {
      fontSize: "25px",
      paddingBottom: "10px",
      marginInlineStart: "10px",
    },
  },
  items: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down(900)]: {
      width: "90%",
    },
  },
  itemlist: {
    display: "flex",
    alignItems: "start",
    marginBottom: "15px",
    flexDirection: "column",
    [theme.breakpoints.down(900)]: {
      marginBottom: "20px",
    },
  },
  roadMapMain: {
    display: "flex",
  },
  pointer: {
    color: "white",
    fontSize: "20px",
    marginBottom: "2vh",
    marginLeft: "165px",
  },
  img: {
    width: "120px",
    height: "100px",
    [theme.breakpoints.down(900)]: {
      width: "70px",
      height: "60px",
    },
  },
  desc: {
    marginLeft: "60px",
    fontSize: "30px",
    color: "#ffffff",
    alignSelf: "center",
    [theme.breakpoints.down(900)]: {
      marginLeft: "10px",
      fontSize: "25px",
      letterSpacing: "3px",
    },
  },
  footer: {
    width: "90%",
  },
}));
